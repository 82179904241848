import {
  bigNumberToNumber,
  getTotalWeightNumber,
  stringToBigNumber,
} from "common/helpers/bignumber"
import type { Governor, Maybe, VoteStats } from "query/graphql"
import { GovernorType, VoteType } from "query/graphql"

const getProposalVotesWeight = (
  type: GovernorType,
  voteStats?:
    | {
        votesCount: string
        votersCount: number
        type: VoteType
      }[]
    | null,
) => {
  const quorumVoteTypeFilter =
    type === GovernorType.Governoralpha || type === GovernorType.Governorbravo
      ? [VoteType.For]
      : [VoteType.For, VoteType.Abstain]

  const weights = voteStats
    ? voteStats
        ?.filter((voteStat) => quorumVoteTypeFilter.includes(voteStat.type))
        .map(({ votesCount }) => `${votesCount}`)
    : []

  return weights
}

type VoteStatsQuorum = Pick<VoteStats, "votesCount" | "votersCount" | "type">

export function wasDefeatedByQuorum(
  governor: Pick<Governor, "type">,
  quorum: string,
  decimals: number,
  voteStats?: Maybe<VoteStatsQuorum[]>,
) {
  const { type } = governor
  const weights = getProposalVotesWeight(type, voteStats)

  const totalWeight = weights
    ? getTotalWeightNumber(weights, decimals)
    : undefined
  const bigQuorum = stringToBigNumber(quorum)
  const quorumWeight = bigNumberToNumber(bigQuorum, decimals)

  if (!totalWeight) return false

  return totalWeight < quorumWeight
}
